import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCountries } from '../../api/checkout/actions'

/**
 * Retrieve countries from state and turn into array
 * @param {array} collection
 * @returns
 */
const getCountries = (s) => {
	var items = s.checkout.location.countries || {}
	let collection = []
	collection = Object.values(items)
	return collection
}

/**
 * Map array of countries  to list of options for the Select
 * @param {array} collection array collection of all countries
 * @param {array} allowOnly array of select alowed country alpha2Code codes
 * @returns
 */
export const mapCountries = (collection, allowOnly) => {
	let result = []
	collection.forEach((el) => {
		if (allowOnly) {
			if (allowOnly.includes(el?.alpha2Code))
				result.push({
					value: el?.alpha2Code,
					label: el?.name,
					prefix: `+${el?.callingCodes[0]}`,
					flag: el?.flag,
				})
		} else {
			result.push({ value: el?.alpha2Code, label: el?.name, prefix: `+${el?.callingCodes[0]}`, flag: el?.flag })
		}
	})
	result.sort((a, b) => a.label?.localeCompare(b.label))

	return result
}
/**
 *
 * @param {Array} allowOnly An array of country codes to allow in response ex: ['US','CA']
 * @returns
 */
const useCountries = (allowOnly, fetchOnMount = true) => {
	const countryOptions = useSelector((s) => getCountries(s))
	const dispatch = useDispatch()
	const [countries, setCountries] = useState([])
	const [fetch, setFetch] = useState(fetchOnMount)

	/** Set the countries on mount */
	useEffect(() => {
		if (countryOptions && countryOptions.length > 0) {
			setFetch(true)
		}
	}, [])

	useEffect(() => {
		if (countryOptions && countryOptions.length > 0 && countries.length == 0) {
			const r = mapCountries(countryOptions, allowOnly)
			setCountries(r)
		}
	}, [countryOptions])

	/** Set the countries on mount */
	useEffect(() => {
		if (fetch && !countryOptions.length > 0) {
			/** Retrieve all countries from backend if not loaded */
			dispatch(getAllCountries())
		}
	}, [])

	return countries
}

export default useCountries
