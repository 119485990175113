import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFormState from '../../../../../hooks/useFormState'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet'
import schemaUserEmail from './schema'
import { Container, HStack, chakra, Box, Button } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom'
import { checkAccountExceptOwned } from '../../../../../../api/app/auth'
import validator from 'validator'
import Card from '../../../../atoms/Card'
import SimpleEmail from '../../../../molecules/controls/InputEmail/SimpleEmail'

import { verifyEmail } from '../../../../molecules/controls/InputEmail/utils'

const EmailForm = (props) => {
	/** Load user's email */
	const email = useSelector((state) => state.auth.email || state.forms.UserEmail.email)

	const [isSubmitting, setIsSubmitting] = useState(false)
	/** Hold ref for the timer check */
	const checkTimerRef = useRef(null)

	/** Dispatch hook */
	const dispatch = useDispatch()

	/** Define form configuration and hooks  */
	const { control, handleSubmit, errors, setValue, formState, watch, setError, clearErrors } = useForm({
		defaultValues: { email: '' },
		resolver: yupResolver(schemaUserEmail),
	})

	/** Watch current form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form */
	const onSubmit = async (data) => {
		setIsSubmitting(true)

		const inputValue = data.email.trim().toLowerCase()

		/**
		 * Check if new entered email is the same as the existing email.
		 */
		if (email == inputValue) {
			setError('email', {
				type: 'error',
				message: 'New email can not be the same as the primary email.',
			})
			setIsSubmitting(false)
		} else {
			/**
			 * Otherwise, let's proceed with verification
			 */
			const res = await verifyEmail(inputValue)

			if (!res.isValid) {
				setError('email', {
					type: 'error',
					message: 'Invalid email, please check your entry.',
				})
				setIsSubmitting(false)
			} else {
				/**
				 * If everything is good, bubble up the update
				 */
				props.onSubmit({ email: inputValue })
			}
		}
	}

	/** Check email account validation method */
	const handleCheckAccount = () => {
		values.email && validator.isEmail(values.email) === true
			? dispatch(checkAccountExceptOwned(String(values.email).toLowerCase()))
			: null
	}

	/* ****************************
	 * DEFINE SIDE-EFFECTS
	 * ************************** */
	// Check account when email address has changed
	useEffect(() => {
		values.email !== email && handleCheckAccount()
	}, [values.email])

	/** Update email address value base on the one retrieved from user's auth */
	useEffect(() => setValue('email', email && String(email).toLowerCase()), [email])

	/** Trigger timer after change in value */
	useEffect(() => {
		/** If there is a current running timeout */
		if (checkTimerRef.current !== null) {
			/** Then, Cancel it */
			clearTimeout(checkTimerRef.current)
		}
		/** Set a timeout using the defined ref, after 1s */
		checkTimerRef.current = setTimeout(() => {
			/** Reset values to null, when it runs */
			checkTimerRef.current = null
			/** Validate any non-empty values, or do nothing */
			values.email !== '' && values.email !== email ? handleCheckAccount() : null
		}, 1000)
	}, [setValue, values.email]) /** Run effect when the form value has changed */

	/* ****************************
	 * RENDER
	 * ************************** */

	return (
		<>
			<Helmet>
				<title>VidDay - Profile</title>
			</Helmet>

			<Container maxW="container.sm" py="2rem" variant="main">
				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
						<Box w="full" mb="2rem">
							<SimpleEmail
								control={control}
								errors={errors}
								name="email"
								type="email"
								label="Email"
								// primaryEmail={email}
								placeholder="Enter a new email"
								// formState={formSubState}
								// setError={setError}
								// clearErrors={clearErrors}
								// provider="reacher"
								// verify={false}
								textAlign="center"
							/>

							{/* <Input control={control} errors={errors} name="email" label="Email" /> */}
						</Box>

						<HStack w="full" justify="center">
							<Button variant="ghost" minW="150px" onClick={props.cancelToggle}>
								Cancel
							</Button>
							<Button type="submit" variant="solid" minW="150px" isLoading={isSubmitting}>
								<span>Save</span>
							</Button>
						</HStack>
					</chakra.form>
				</Card>
			</Container>
		</>
	)
}

export default EmailForm
