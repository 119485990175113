import React from 'react'
import { useDispatch } from 'react-redux'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import { useAuthContext } from '../../../contexts/AuthProvider'
import { updateUser } from '../../../../api/app/auth'

const ModalDeleteAccount = ({ onClose, isOpen }) => {
	const dispatch = useDispatch()
	const { userId } = useAuthContext()

	const handleDeactivateUser = () => {
		const now = new Date()
		dispatch(
			// this is equivalent to what DELETE /api/v2/users/:id: would do
			// use DELETE instead of PUT when we stop using bit
			updateUser({
				id: userId,
				deleteRequestedAt: now.toISOString(),
			})
		)
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Warning" />
						<Heading size="lg" variant="hero">
							Are you sure you want to delete your account?
						</Heading>
						<Text>
							Your media, published VidDays and account details will be removed and no longer be available
							to access, view or download.
						</Text>
						<Text>This includes all third-party uploaded media from Facebook, Google and Dropbox.</Text>
						<Text fontStyle="italic">
							Account deletion may take up to 24 hours, signing back into your account before deletion
							will interrupt the process.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={() => {
								handleDeactivateUser()
								onClose()
							}}>
							Yes, Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalDeleteAccount
