import { useSelector } from 'react-redux'

const useEntities = (name = undefined) => {
	if (!name) throw new Error('A name is required as first argument to retrieve entities.')

	const entities = useSelector((state) =>
		state.entities && state.entities[name] !== undefined ? state.entities[name].entities : null
	)

	const isFetching = useSelector((state) =>
		state.entities && state.entities[name] !== undefined ? state.entities[name].isFetching : null
	)

	return [isFetching, entities]
}

export default useEntities
