import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet'
import { updateProfile } from '../../../../../api/app/profile'
import { chakra, Heading, Divider, Alert, Text, Box, Container } from '@chakra-ui/react'
import Card from '../../../atoms/Card'
import schemaUserNotifications from './schema'
// import Checkbox from '../controls/checkbox'
import Switch from '../../../molecules/controls/Switch'
import usePreferences from '../../../../hooks/usePreferences'

export const lower = (value) => value && value.toLowerCase()

const Notifications = (props) => {
	/* ****************************
	 * DEFINE REDUX VALUES
	 * ************************** */

	const profileId = useSelector((state) => state.auth?.profile?.id)

	/** Obtain values from the redux store */
	const initialValues = useSelector((state) =>
		state.auth && state.auth.profile && state.auth.profile.notifications ? state.auth.profile.notifications : {}
	)
	const { eventActivity, features, reminders, eventInformation, promos } = initialValues

	/* ****************************
	 * DEFINE HOOKS
	 * ************************** */

	/** Dispatch hook */
	const dispatch = useDispatch()

	/** Define form configuration and hooks  */
	const { control, register, handleSubmit, errors, reset, watch, setValue, trigger, formState } = useForm({
		resolver: yupResolver(schemaUserNotifications),
	})

	/* ****************************
	 * UPDATE FORM VALUES & SIDE EFFECTS
	 * ************************** */

	/** Update form values with new initialValues when they become available */
	useEffect(() => setValue('notifications[eventActivity]', eventActivity), [eventActivity])
	useEffect(() => setValue('notifications[features]', features), [features])
	useEffect(() => setValue('notifications[reminders]', reminders), [reminders])
	useEffect(() => setValue('notifications[eventInformation]', eventInformation), [eventInformation])
	useEffect(() => setValue('notifications[promos]', promos), [promos])

	const handleUpdate = ({ notifications, groupId, subscribed }) => {
		/** Dispatch to DB */
		dispatch(
			updateProfile(
				{
					id: profileId,
					notifications: notifications,
				},
				true
			)
		)
	}

	/** Watch eventActivity form values */
	const eventActivityValue = watch('notifications[eventActivity]')
	useEffect(() => {
		if (eventActivityValue != undefined && eventActivityValue != eventActivity) {
			handleUpdate({
				notifications: {
					eventActivity: eventActivityValue,
				},
				groupId: 'c11ac503-2063-4fed-a35c-cfefc8e74a1b',
				subscribed: eventActivityValue,
			})
		}
	}, [eventActivityValue])

	/** Watch features form values */
	const featuresValue = watch('notifications[features]')
	useEffect(() => {
		if (featuresValue != undefined && featuresValue != features) {
			handleUpdate({
				notifications: {
					features: featuresValue,
				},
				groupId: '2fd65862-21b1-4778-8a7e-04eb364b30a5',
				subscribed: featuresValue,
			})
		}
	}, [featuresValue])

	/** Watch reminders form values */
	const remindersValue = watch('notifications[reminders]')
	useEffect(() => {
		if (remindersValue != undefined && remindersValue != reminders) {
			handleUpdate({
				notifications: {
					reminders: remindersValue,
				},
				groupId: '516da22d-3976-4fe0-81c1-43f8e59ce4b7',
				subscribed: remindersValue,
			})
		}
	}, [remindersValue])

	/** Watch eventInformation form values */
	const eventInformationValue = watch('notifications[eventInformation]')
	useEffect(() => {
		if (eventInformationValue != undefined && eventInformationValue != eventInformation) {
			handleUpdate({
				notifications: {
					eventInformation: eventInformationValue,
				},
				groupId: '516da22d-3976-4fe0-81c1-43f8e59ce4b7',
				subscribed: eventInformationValue,
			})
		}
	}, [])

	/** Watch promos form values */
	const promosValue = watch('notifications[promos]')
	useEffect(() => {
		if (promosValue != undefined && promosValue != promos) {
			handleUpdate({
				notifications: {
					promos: promosValue,
				},
				groupId: '305b0916-556e-4827-aaf7-bf95ee866588',
				subscribed: promosValue,
			})
		}
	}, [promosValue])

	return (
		<>
			<Helmet>
				<title>VidDay - Notifications</title>
			</Helmet>

			<Container maxW="container.md" py="2rem" variant="main">
				<chakra.form w="full">
					<Card
						px={['1rem', '2rem']}
						py="2rem"
						alignItems="flex-start"
						variant="main"
						mb="2rem"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<Heading as="h3" size="md">
							As a creator
						</Heading>

						<Divider my="1rem" />

						<Switch
							errors={errors}
							control={control}
							name="notifications[eventActivity]"
							defaultValue={false}
							label="Event Activity"
							description="Participation related emails - submissions, rsvps and more."
						/>

						<Divider my="1rem" />

						<Switch
							errors={errors}
							control={control}
							name="notifications[features]"
							defaultValue={false}
							label="Recommendations"
							description="Helpful emails to help you make a successful event."
						/>
					</Card>

					<Card
						px={['1rem', '2rem']}
						py="2rem"
						alignItems="flex-start"
						variant="main"
						mb="2rem"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<Heading as="h3" size="md">
							As a contributor
						</Heading>

						<Divider my="1rem" />

						<Switch
							errors={errors}
							control={control}
							name="notifications[reminders]"
							defaultValue={false}
							label="Reminders"
							description="Email reminders to submit when invited to participate in a VidDay."
						/>

						<Divider my="1rem" />

						<Switch
							errors={errors}
							control={control}
							name="notifications[eventInformation]"
							defaultValue={false}
							label="Event Details"
							description="Various email notifications from events you've participated in."
						/>
					</Card>

					<Card
						px={['1rem', '2rem']}
						py="2rem"
						alignItems="flex-start"
						variant="main"
						mb="2rem"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<Heading as="h3" size="md">
							General
						</Heading>

						<Divider my="1rem" />

						<Switch
							errors={errors}
							control={control}
							name="notifications[promos]"
							defaultValue={false}
							label="Stay Current"
							description="Occasional emails with exclusive offers and VidDay updates."
						/>
					</Card>

					<Box mx={['1rem', 0]}>
						<Alert status="warning" borderRadius="base" mb="1rem" backgroundColor="rgba(255,175,47, 0.1)">
							<Text fontSize="0.875rem" color="#47596A">
								⚠️ <strong>Please note:</strong> Transactional emails (payment details, starting or
								finalizing a VidDay) will continue to be sent.
							</Text>
						</Alert>
					</Box>
				</chakra.form>
			</Container>
		</>
	)
}

export default Notifications
