import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUserLocation } from '@vidday/utils'
import ProfileForm from './ProfileForm'
import EmailForm from './EmailForm'
import { updateProfile } from '../../../../../api/app/profile'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { updatePrimaryEmailAddress } from '../../../../../api/actions'

const Profile = (props) => {
	const { section } = useParams()
	const user = useAuthContext()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const toggleEmailForm = () => {
		if (section == 'email') {
			navigate('/settings/profile')
		} else navigate('/settings/email')
	}

	const handleSubmitEmailForm = (data) => {
		data.id = user.userId

		dispatch(updatePrimaryEmailAddress(data))
		navigate(`/settings/profile`)
	}

	const handleUpdateProfile = (data) => {
		// Add userprofile id to payload
		data.id = user.profile.id
		delete data.email

		setIsSubmitting(true)
		getUserLocation()
			.then(function (location) {
				data.location = location //update current user location
				dispatch(updateProfile(data))
				setIsSubmitting(false)
			})
			.catch(function (error) {
				//console.error('getUserLocation error : ', error)
				data.location = null
				dispatch(updateProfile(data))
				setIsSubmitting(false)
			})
	}

	if (section == 'email') {
		return <EmailForm onSubmit={handleSubmitEmailForm} cancelToggle={toggleEmailForm} />
	} else {
		return (
			<ProfileForm
				isSubmitting={isSubmitting}
				onSubmit={handleUpdateProfile}
				onResend={handleSubmitEmailForm}
				toggleEmailForm={toggleEmailForm}
			/>
		)
	}
}

export default Profile
