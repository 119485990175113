import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getLocation } from '../../api/checkout/actions'
// import useProfile from './useProfile'

/**
 * Hook use to manage the location to be used for the checkout flow.
 */
const useLocationSetup = () => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()

	/**
	 * Retrieve user's location through geo-ip on mount
	 */
	useEffect(() => {
		/** Retrieve user's location */
		// console.log("GET CURRENT LOCATION")
		dispatch(getLocation())
	}, [])
}

export default useLocationSetup
