import * as Yup from 'yup'
const namePattern = /^(?!.*\/|.*\\|.*@|.*\.[^\s]).*$/

export const schemaUserProfile = Yup.object().shape({
	countryCode: Yup.string()
		.default(null)
		.transform((v, o) => (o === '' ? null : v))
		.nullable(),
	regionCode: Yup.string()
		.default(null)
		.transform((v, o) => (o === '' ? null : v))
		.nullable(),
	firstName: Yup.string()
		.required('You need to provide a first name.')
		.max(32, 'First name cannot exceed 32 characters.')
		.matches(namePattern, 'First name cannot contain any links or URL patterns.'),
	lastName: Yup.string()
		.required('You need to provide a last name.')
		.max(32, 'Last name cannot exceed 32 characters.')
		.matches(namePattern, 'Last name cannot contain any links or URL patterns.'),
	birthday: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.notRequired(),
	sms: Yup.string()
		.matches(/[0-9]{9,15}/, { message: 'Phone number is not valid.', excludeEmptyString: true })
		.default('')
		.notRequired()
		.transform((v, o) => (o === '' ? null : v))
		.nullable(),
	smsCountryCode: Yup.string().notRequired().nullable(),
	// .when('sms', {
	// 	is: (val) => val != '' && val != null,
	// 	then: (schema) => schema.default('US').required(), //.transform((v, o) => (o === '' ? 'US' : v)),//.required().transform((v, o) => (o === '' ? null : v)).nullable(),
	// 	otherwise: (schema) => schema.notRequired().nullable().default(null),
	// }),
})

export default schemaUserProfile
