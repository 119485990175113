import * as Yup from 'yup'

export const schema = Yup.object().shape({
	notifications: Yup.object().shape({
		// creators
		eventActivity: Yup.boolean().oneOf([true, false], 'Required terms of use').required('Required terms of use'),
		features: Yup.boolean().oneOf([true, false], 'Required terms of use').required('Required terms of use'),
		// contributors
		reminders: Yup.boolean().oneOf([true, false], 'Required terms of use').required('Required terms of use'),
		eventInformation: Yup.boolean().oneOf([true, false], 'Required terms of use').required('Required terms of use'),
		// all users
		promos: Yup.boolean().oneOf([true, false], 'Required terms of use').required('Required terms of use'),
	}),
})

export default schema
