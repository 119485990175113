import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import useFormState from '../../../../../hooks/useFormState'
import useLocationSetup from '../../../../../hooks/useLocationSetup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet'
import schemaUserProfile from './schema'
import { Container, chakra, SimpleGrid, Box, Button, Text, Divider, HStack, useDisclosure } from '@chakra-ui/react'
import Input from '../../../../molecules/controls/Input'
import Date from '../../../../molecules/controls/Date'
import Select from '../../../../molecules/controls/Select'
import PhoneNumber from '../../../../molecules/controls/PhoneNumber'
import Card from '../../../../atoms/Card'
import ModalDeleteAccount from '../../../../organisms/ModalDeleteAccount'
import useCountries from '../../../../../hooks/useCountries'

/**
 * Retrieve regions from state and turn into array
 * @param {array} collection
 * @returns
 */
const getRegions = (s) => {
	var items = s.checkout.location.regions || {}
	let collection = []
	collection = Object.values(items)
	return collection
}

/**
 * Map array of regions to list of options for the Select
 * @param {array} collection
 * @returns
 */
const mapRegions = (collection, countryCode) => {
	let result = []
	if (countryCode) {
		collection.forEach((el, i) => {
			if (el.countryCode == countryCode) {
				result.push({ value: el.code, label: el.name })
			}
		})
	}
	return result
}

const ProfileForm = (props) => {
	/** Set up Checkout Location information */
	useLocationSetup()
	const deleteModal = useDisclosure()
	/** Get email address from redux store */
	const user = useAuthContext()
	const { email, isImpersonated, role } = user
	/** Get temporary email address from redux store (if any) */
	const temporaryEmail = useSelector((state) => state.auth.temporaryEmail)
	/** Obtain values from the redux store */
	const googleId = useSelector((state) => state.auth && state.auth.googleId)
	const facebookId = useSelector((state) => state.auth && state.auth.facebookId)
	const appleId = useSelector((state) => state.auth && state.auth.appleId)

	const initialValues = useSelector((state) => (state.auth && state.auth.profile ? state.auth.profile : {}))
	/** Store values of the form within the component */
	const { firstName, lastName, birthday, sms, smsCountryCode, countryCode, regionCode } = initialValues

	const regionOptions = useSelector((s) => getRegions(s))

	/** Should region field (and postal code) be displayed? */
	const [shouldDisplayRegions, setDisplayRegions] = useState(false)

	/** List used for select controls */
	const [regions, setRegions] = useState([])
	const countries = useCountries()

	/** Define form configuration and hooks  */
	const { control, handleSubmit, errors, setValue, formState, watch, setError, clearErrors, register } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(schemaUserProfile),
	})
	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (data) => {
		// Clean hack to ensure smsCountryCode is set properly (until we move to nextjs)
		let _smsCountryCode = data.smsCountryCode

		if (data.sms && !data.smsCountryCode) {
			_smsCountryCode = data.countryCode || 'US'
		}

		props.onSubmit({ ...data, smsCountryCode: _smsCountryCode })
	}

	/* ****************************
	 * UPDATE STATES AND SIDE-EFFECTS
	 * ************************** */

	/** Register custom fields */
	useEffect(() => {
		register({ name: 'sms' })
		register({ name: 'smsCountryCode' })
	}, [register, sms, smsCountryCode])

	/** Update form values with new initialValues when they become available */
	useEffect(() => setValue('firstName', firstName), [firstName])
	useEffect(() => setValue('lastName', lastName), [lastName])
	useEffect(() => setValue('birthday', birthday), [birthday])
	useEffect(() => setValue('countryCode', countryCode), [countryCode])
	useEffect(() => setValue('regionCode', regionCode), [regionCode, countryCode])
	useEffect(() => setValue('sms', sms), [sms])
	useEffect(() => setValue('smsCountryCode', smsCountryCode), [smsCountryCode])

	/** Update regions options if available */
	useEffect(() => {
		/** Check if we at least provided a value for the country code */
		// if (!isFetchingCountries && values.countryCode != '' && values.countryCode != null) {
		if (regionOptions && (values.countryCode == 'US' || values.countryCode == 'CA')) {
			const r = mapRegions(regionOptions, values.countryCode)
			setDisplayRegions(true)
			setRegions(r)
		} else {
			setDisplayRegions(false)
			setRegions([])
			setValue('regionCode', null)
		}
		// } else {
		// 	setDisplayRegions(false)
		// }
	}, [values.countryCode])

	return (
		<>
			<Helmet>
				<title>VidDay - Profile</title>
			</Helmet>
			<Container maxW="container.md" py="2rem" variant="main">
				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
						<SimpleGrid mb="1rem" spacing="1rem" columns={[1, 2]}>
							<Input control={control} errors={errors} name="firstName" label="First Name" />

							<Input control={control} errors={errors} name="lastName" label="Last Name" />
						</SimpleGrid>

						<SimpleGrid spacing="1rem" mb="1rem" columns={[1, 2]}>
							<Box textAlign="left">
								<Input
									control={control}
									errors={errors}
									name="email"
									label="Email"
									defaultValue={email}
									readOnly
								/>

								{temporaryEmail && (
									<Text fontSize="0.875rem">
										waiting for <strong>{temporaryEmail}</strong>
									</Text>
								)}

								{
									<chakra.a
										sx={{
											fontSize: '0.875rem',
											color: 'var(--vidday-colors-link)',
											_hover: {
												cursor: 'pointer',
												textDecoration: 'underline',
											},
										}}
										onClick={props.toggleEmailForm}>
										Change primary email
									</chakra.a>
								}
							</Box>
							<PhoneNumber
								control={control}
								errors={errors}
								name="sms"
								label="Mobile SMS"
								placeholder="Enter phone number"
								setError={setError}
								clearErrors={clearErrors}
								register={register}
							/>
						</SimpleGrid>

						<Divider my="1rem" borderStyle="dashed" />

						<Date
							control={control}
							errors={errors}
							name="birthday"
							placeholder="Birthday (optional)"
							label="Birthday"
							allowPast
							saveLabel="Done"
						/>

						<Divider my="1rem" borderStyle="dashed" />

						<SimpleGrid spacing="1rem" mb="2rem" columns={[1, 2]}>
							<Select
								control={control}
								errors={errors}
								name="countryCode"
								placeholder="Country"
								label="Country"
								options={countries.map((el, i) => (
									<option key={i} value={el.value}>
										{el.label}
									</option>
								))}
							/>

							<Box visibility={shouldDisplayRegions ? 'visible' : 'hidden'}>
								<Select
									control={control}
									errors={errors}
									name="regionCode"
									placeholder="Region"
									label="Region"
									disabled={!values.countryCode}
									options={regions.map((el, i) => (
										<option key={i} value={el.value}>
											{el.label}
										</option>
									))}
								/>
							</Box>
						</SimpleGrid>
						<HStack justifyContent="space-between">
							<Button colorScheme="red" variant="outline" minW="150px" onClick={deleteModal.onOpen}>
								Delete Account
							</Button>
							<Button type="submit" variant="solid" minW="150px" isLoading={props.isSubmitting}>
								<span>Save</span>
							</Button>
						</HStack>
					</chakra.form>
				</Card>
			</Container>
			<ModalDeleteAccount isOpen={deleteModal.isOpen} onClose={deleteModal.onClose} />
		</>
	)
}

export default ProfileForm
