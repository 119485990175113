import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet'
import schemaUserPassword from './schema'
import { updatePassword } from '../../../../../api/app/auth'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import useFormState from '../../../../hooks/useFormState'
import Password from '../../../molecules/controls/Password'
import { Container, chakra, VStack, HStack, Button } from '@chakra-ui/react'
import Card from '../../../atoms/Card'

const PasswordForm = (props) => {
	/** Dispatch hook */
	const dispatch = useDispatch()
	const { userId } = useAuthContext()

	/** We need to prevent double submit, so let's check if we're submitting the form */
	const { status, data: reduxFormValues } = useFormState('UserPassword')

	/** Define form configuration and hooks  */
	const { control, register, handleSubmit, errors, reset, watch, setValue, trigger, formState } = useForm({
		defaultValues: { password: '', passwordConfirmation: '', currentPassword: '' },
		resolver: yupResolver(schemaUserPassword),
	})

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (data) => {
		/** Add users id to the action payload */
		data.id = userId

		dispatch(updatePassword(data))
		/** Reset form */
		reset()
	}

	return (
		<>
			<Helmet>
				<title>VidDay - Change Password</title>
			</Helmet>
			<Container maxW="container.sm" py="2rem" variant="main">
				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
						<VStack spacing="1rem" mb="2rem">
							<Password
								control={control}
								errors={errors}
								name="currentPassword"
								label="Current Password"
							/>

							<Password
								control={control}
								errors={errors}
								name="password"
								label="New Password"
								includestrength={true}
							/>

							<Password
								control={control}
								errors={errors}
								name="passwordConfirmation"
								label="Confirm New Password"
							/>
						</VStack>

						<HStack w="full" justify="center">
							<Button
								type="submit"
								variant="solid"
								minW="150px"
								disabled={Object.keys(errors).length > 0}
								isLoading={status.isFetching || formState.isSubmitting}>
								<span>Save</span>
							</Button>
						</HStack>
					</chakra.form>
				</Card>
			</Container>
		</>
	)
}

export default PasswordForm
