import React from 'react'
import { Box, HStack, useColorModeValue } from '@chakra-ui/react'
import DashboardLink from '../../atoms/DashboardLink'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import FooterBase from '../../molecules/FooterBase'
import Profile from './Profile'
import Notifications from './Notifications'
import Billing from './Billing'
import Password from './Password'
import useProfile from '../../../hooks/useProfile'

const AccountSettings = (props) => {
	const { section } = useParams()
	useProfile() // Fetch profile info for all routes

	return (
		<>
			<Helmet>
				<title>VidDay - Settings</title>
				<meta name="robots" content="noindex nofollow noarchive" />
			</Helmet>

			<Box>
				<Box as="nav" width="full" boxShadow="base" bg={useColorModeValue('white', 'gray.900')}>
					<HStack
						h="80px"
						spacing={4}
						px={4}
						w="full"
						overflowX="auto"
						sx={{
							'scrollbarWidth': 'none',
							' -ms-overflow-style': 'none',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
						}}>
						<DashboardLink isActive={section == 'profile' || section == 'email'} to="/settings/profile">
							Profile
						</DashboardLink>

						<DashboardLink isActive={section == 'password'} to="/settings/password">
							Password
						</DashboardLink>

						<DashboardLink isActive={section == 'notifications'} to="/settings/notifications">
							Notifications
						</DashboardLink>

						<DashboardLink isActive={section == 'billing'} to="/settings/billing">
							Billing
						</DashboardLink>
					</HStack>
				</Box>
			</Box>

			{section == 'profile' && <Profile />}
			{section == 'email' && <Profile type="email" />}
			{section == 'notifications' && <Notifications />}
			{section == 'billing' && <Billing />}
			{section == 'password' && <Password />}

			<FooterBase />
		</>
	)
}

export default AccountSettings
