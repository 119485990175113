import { useEffect, useState } from 'react'
import { fetchInvoices } from '../../api/app/invoices'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useAuthContext } from '../contexts/AuthProvider'
import dayjs from 'dayjs'

/**
 * @returns invoice entities
 */
const useInvoices = (fetchOnMount) => {
	const invoices = useSelector((s) => s.entities.invoices.entities, shallowEqual)
	const isFetching = useSelector((s) => s.entities.invoices.isFetching, shallowEqual)
	const dispatch = useDispatch()
	const { userId } = useAuthContext()
	/** Set on mount */
	useEffect(() => {
		if (fetchOnMount) {
			/** Retrieve all from backend */
			dispatch(fetchInvoices(userId))
		}
	}, [])
	return { isFetching, invoices }
}

export default useInvoices
